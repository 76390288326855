import axios from '@/apis/axios';
import store from '@/store';

/**
 * プラン情報取得
 */
export const getPlans = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 保険金額情報取得
 */
export const getPlanPayout = async planId => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans/${planId}/plan_payout`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 保証情報取得
 */
export const getPlanCoverages = async planId => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans/${planId}/plan_coverages`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 担保特約情報取得
 */
export const getCollateralPlanOptions = async planId => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans/${planId}/collateral_plan_options`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 特約情報取得
 */
export const getPlanOptions = async planId => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans/${planId}/plan_options`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 保険料情報取得
 */
export const getPlanPremium = async planId => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans/${planId}/plan_premium`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 支払い方法情報取得
 */
export const getPayment = async planId => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/plans/${planId}/payments`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
