var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title1"),
        },
      }),
      _c("v-col", { staticClass: "pt-0" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("description.reviewContract.explanation1"))),
        ]),
      ]),
      !_vm.finalReviewFlag
        ? _c(
            "v-container",
            { staticClass: "pt-0" },
            [
              _c(
                "v-col",
                _vm._l(_vm.planChoices, function (plan) {
                  return _c(
                    "v-row",
                    {
                      key: plan.key,
                      staticClass: "p-0",
                      staticStyle: { "align-items": "center" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "5" } }, [
                        _c("label", { attrs: { for: plan.key } }, [
                          _vm._v(_vm._s(plan.planName)),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "1" } }, [
                        plan.comment
                          ? _c("div", [
                              _c("div", {
                                staticClass: "plan-explanation ml-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.openPlanExplanationDialog(plan)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              staticStyle: { "font-size": "10px" },
                              attrs: {
                                align: "center",
                                width: "15px",
                                "max-width": "30px",
                                height: "25px",
                                color:
                                  plan.key === _vm.selectedTab
                                    ? "next"
                                    : "disabled",
                                dark: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.targetTabData(plan.key)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("button.display")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("ItemExplanationDialog", {
        ref: `planExplanationDialog${_vm.plan.id}`,
        attrs: {
          maxWidth: 320,
          showDialog: _vm.showDialog,
          title: _vm.plan.planName,
          text: _vm.plan.comment,
          negativeButtonTitle: _vm.$t("button.close"),
          onClickNegativeButton: _vm.closePlanExplanationDialog,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title2"),
          "content-page-name": _vm.contentPageName,
          records: _vm.basicInformationRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title3"),
          "content-page-name": _vm.contentPageName,
          records: _vm.insuranceAmountRecords,
        },
      }),
      _c("PlanCoverageTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title4"),
          "content-page-name": _vm.contentPageName,
          plan: _vm.displayPlan,
          records: _vm.damageInsuranceAmountRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title5"),
          "content-page-name": _vm.contentPageName,
          records: _vm.earthquakeInsuranceRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title6"),
          "content-page-name": _vm.contentPageName,
          records: _vm.costBurdenAmountRecords,
        },
      }),
      _c("CollateralPlanOptionTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title7"),
          "content-page-name": _vm.contentPageName,
          records: _vm.collateralPlanOptionRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title8"),
          "content-page-name": _vm.contentPageName,
          records: _vm.autoSetPlanOptionRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title9"),
          "content-page-name": _vm.contentPageName,
          records: _vm.anotherPlanOptionRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title10"),
          "content-page-name": _vm.contentPageName,
          records: _vm.discountRateRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title11"),
          "content-page-name": _vm.contentPageName,
          records: _vm.paymentMethodRecords,
        },
      }),
      _c("PlanTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepThirdContent.title12"),
          "content-page-name": _vm.contentPageName,
          records: _vm.totalPremiumRecords,
        },
      }),
      _c("ScrollToTopButton"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }