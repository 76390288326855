<template>
  <div
    v-if="showScrollToTopButton"
    class="scroll-to-top-button"
    @click="scrollToTop"
  >
    <img src="@/assets/img/scroll-to-top.png"
      width="50px"
      height="50px"
      />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showScrollToTopButton: false,
    };
  },
  mounted() {
    // スクロール操作を監視するため、イベントリスナーを追加する
    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    // Vueインスタンスが破棄される前に、追加したイベントリスナーを削除しておく
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    scrollHandler() {
      // スクロールしたら、画面上部に戻るためのボタンを表示する
      this.showScrollToTopButton = window.scrollY > 0;
    },
    scrollToTop() {
      // 該当ボタン押下時、画面上部にスクロールさせる
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
<style>
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
</style>
