var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showScrollToTopButton
    ? _c(
        "div",
        { staticClass: "scroll-to-top-button", on: { click: _vm.scrollToTop } },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/img/scroll-to-top.png"),
              width: "50px",
              height: "50px",
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }